import {Cache} from '@admin-tribe/acsc';
import {
  ProjectFilter,
  ProjectOrder,
  ProjectViews,
  STATE_CHANGED,
} from '@ccx-public/shared-asset-model-js';

const SHARED_PROJECT_COUNT_CACHE_ID = 'ActiveSharedProjectCountCacheId';
const PROJECT_LIST_LIMIT = 50;

const sharedProjectsUtilsCache = new Cache();

const getSharedProjectList = async (
  sharedAssetModel,
  sortBy = 'name',
  limit = PROJECT_LIST_LIMIT,
  loadAll = true,
  sortDirection = 'ascending'
  // eslint-disable-next-line max-params -- need to be able to have these extra params
) => {
  const filter = new ProjectFilter({
    view: ProjectViews.Admin,
  });

  const projectList = sharedAssetModel.projectContext
    .projects()
    .getList(new ProjectOrder(sortDirection, sortBy), filter, {
      limit,
      loadAllPages: loadAll,
    });
  await projectList.refresh();
  await new Promise((resolve) => {
    const listener = () => {
      projectList.off(STATE_CHANGED, listener);
      resolve();
    };
    projectList.on(STATE_CHANGED, listener);
  });
  return projectList;
};

/**
 * @description Get the active project count
 *
 * @param {ISAMCore} sharedAssetModel - shared asset model
 * @returns {Promise<number>} a promise which resolves with the active project count
 */
const getActiveSharedProjectCount = async (sharedAssetModel) => {
  const cachedPromise = sharedProjectsUtilsCache.get(SHARED_PROJECT_COUNT_CACHE_ID);
  if (cachedPromise) {
    return cachedPromise;
  }
  const projectList = await getSharedProjectList(sharedAssetModel);
  sharedProjectsUtilsCache.put(SHARED_PROJECT_COUNT_CACHE_ID, projectList.total);
  return projectList.total;
};

/**
 * @description Get the project object given a project ID
 *
 * @param {ISAMCore} sharedAssetModel - shared asset model
 * @param {string} projectId - ID of the project
 * @returns {Promise} a promise which resolves with the given project (or undefined if there is no match)
 */
const getSharedProjectFromId = async (sharedAssetModel, projectId) => {
  const projectContext = await sharedAssetModel.getProjectContext();
  return projectContext.getProjectbyID(projectId);
};

export {getActiveSharedProjectCount, getSharedProjectFromId, SHARED_PROJECT_COUNT_CACHE_ID};
