/**
 * Defines the Third Party GenAI models Usage control page under the settings tab.
 */
import {dispatchUiEventAnalytics, log} from '@admin-tribe/acsc';
import {
  Subpage,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Switch, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {useId} from '@react-aria/utils';
import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import useCollaborationPolicies from 'features/settings/hooks/use-collaboration-policies/useCollaborationPolicies';

/**
 * This tab is used to control the usage of third party GenAI models.
 */
const NonAdobeGenAiModelsSubpage = () => {
  const intl = useIntl();
  const descriptionId = useId();
  const [isSaving, setIsSaving] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const orgId = rootStore.organizationStore.activeOrgId;

  // Retrieve organization's current policy
  const {
    collaborationPolicies,
    error: errorLoadingPolicies,
    isLoading,
  } = useCollaborationPolicies({orgId});

  /**
   * Rerender the switch (or trigger an error) when the collaboration policies are loaded
   */
  useEffect(() => {
    if (collaborationPolicies) {
      const settings = collaborationPolicies.getThirdPartyAIModelsUsagePolicyEnabled();
      setIsEnabled(settings);
    } else if (errorLoadingPolicies) {
      log.error(
        `Failed to load Third Party AI Models Usage Policy due to error: ${errorLoadingPolicies}`
      );
      showErrorToast();
    }
  }, [collaborationPolicies, errorLoadingPolicies]);

  const onChange = useCallback(
    async (value) => {
      setIsSaving(true);
      try {
        await collaborationPolicies.setThirdPartyAIModelsUsagePolicy(value);
        setIsEnabled(value);
        showSuccessToast();
        dispatchUiEventAnalytics({
          eventAction: `${value === true ? 'on' : 'off'}`,
          eventName: `thirdPartyModels`,
          interaction: {
            impression: 'thirdPartyModels',
          },
          orgId,
        });
      } catch (error) {
        log.error(`Failed to toggle third party GenAI Models Usage Policy due to error: ${error}`);
        showErrorToast(
          intl.formatMessage(
            {id: 'settings.assetSettings.nonAdobeGenerativeAIModels.error'},
            {error}
          )
        );
      }
      setIsSaving(false);
    },
    [collaborationPolicies, intl, orgId]
  );

  return (
    <Subpage
      data-testid="asset-settings-nonAdobeGenerativeAIModels-subpage"
      isBumpered={!!errorLoadingPolicies}
      isLoading={isLoading || isSaving}
    >
      <View marginBottom="size-100">
        <Text data-testid="description" id={descriptionId}>
          {intl.formatMessage(
            {id: 'settings.assetSettings.nonAdobeGenerativeAIModels.description'},
            {
              goUrl: (str) => <GoUrl name="aac_gen_ai_approach">{str}</GoUrl>,
            }
          )}
        </Text>
      </View>
      <View>
        <Switch
          aria-describedby={descriptionId}
          isDisabled={errorLoadingPolicies}
          isSelected={isEnabled}
          onChange={onChange}
        >
          <FormattedMessage
            id={`settings.assetSettings.nonAdobeGenerativeAIModels.status.${
              isEnabled ? 'enabled' : 'disabled'
            }`}
          />
        </Switch>
      </View>
    </Subpage>
  );
};

export default NonAdobeGenAiModelsSubpage;
