// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import React from 'react';

import {getIntroductionsData} from 'common/services/sophia/shared-contextual-params/sharedContextualParamsUtils';

/**
 * Hook to fetch introductions statistics, generally from a cached store.
 *
 * This is primarily to provide an slim abstract to getting User Introductions statistics, while
 * waiting for https://jira.corp.adobe.com/browse/ONESIE-45257 to be implemented on ACRS.
 *
 * NOTE: Currently it is using the SophiaContextualParams as its store, as that has introductions data
 * it retrieves and caches. The hook will attempt to fetch on every render, relying on the caching layer
 * in getIntroductionsData to prevent unnecessary network requests.
 *
 * @returns {object} The introductions data object, loading state, and any error that occurred
 */
const useIntroductionsStats = () => {
  // Track if we're currently loading data
  const isLoadingRef = React.useRef(false);

  // State for the actual data and any error
  const [state, setState] = React.useState({
    data: undefined,
    error: undefined,
    isLoading: true, // Start with loading true since we'll fetch immediately
  });

  // Run the async request immediately, relying on getIntroductionsData's caching
  (async () => {
    // Don't fetch if already loading
    if (isLoadingRef.current) {
      return;
    }

    isLoadingRef.current = true;

    try {
      const data = await getIntroductionsData();

      // Only update if data has changed
      if (data && JSON.stringify(data) !== JSON.stringify(state.data)) {
        setState({
          data,
          error: undefined,
          isLoading: false,
        });
      }
    } catch (error) {
      setState({
        data: undefined,
        error,
        isLoading: false,
      });
    } finally {
      isLoadingRef.current = false;
    }
  })();

  return state;
};

export default useIntroductionsStats;
